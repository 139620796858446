$charcoal: #444444;
$charcoal-dark: #585858;
$charcoal-ligth: #717171;
$grey-dark: #333333;
$grey-ligth: #D3D3D3;
$pale-grey-main: #777777;

.bg-white {
    background-color: #FFFFFF;
}

.border-bottom-dashed {
    border-bottom: 1px solid #EAEAEA;
    border-bottom-style: dashed;
}

.horizontal-scrolling {
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
}

.seperator {
    height: 1px;
    width: 100%;
    background-color: #F8F8F8;
}

.mini-border-line {
    margin: 18px 0;
    height: 1px;
    width: 100%;
    background-image: linear-gradient(90deg, rgba(216, 216, 216, 0) 0%, #C7C7C7 57.5%, rgba(216, 216, 216, 0) 100%);
}

.pointer {
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

.underline-less {
    text-decoration: none;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.text-charcoal {
    color: $charcoal;
}

.text-charcoal-dark {
    color: $charcoal-dark;
}

.text-charcoal-light {
    color: $charcoal-ligth;
}

.text-grey-dark {
    color: $grey-dark;
}

.text-grey-ligth {
    color: $grey-ligth;
}

.text-pale-grey-main {
    color: $pale-grey-main;
}

.absolute,
.fixed {
    &.bottom {
        bottom: 0;
    }

    &.top {
        top: 0;
    }

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }
}

.inline-block {
    display: inline-block;
}

.flex {
    display: flex;

    &.column {
        flex-direction: column;
    }

    &.row-reverse {
        flex-direction: row-reverse;
    }

    &.justify-around {
        justify-content: space-around;
    }

    &.justify-center {
        justify-content: center
    }

    &.items-center {
        align-items: center
    }

    &.items-end {
        align-items: flex-end
    }

    &.justify-between {
        justify-content: space-between
    }

    &.justify-end {
        justify-content: flex-end
    }
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.radius-lg {
    border-radius: 24px;
}

.radius-md {
    border-radius: 16px;
}

.radius-sm {
    border-radius: 12px;
}

.pr-md {
    padding-right: 16px;
}

.pr-sm {
    padding-right: 12px;
}

.pr-xs {
    padding-right: 4px;
}

.pr-xl {
    padding-right: 36px;
}

.pl-md {
    padding-left: 16px;
}

.pl-1 {
    padding-left: 5px;
}

.pl-sm {
    padding-left: 8px;
}

.pl-xs {
    padding-left: 4px;
}

.pt-md {
    padding-top: 16px;
}

.pt-lg {
    padding-top: 24px;
}

.pt-xl {
    padding-top: 32px;
}

.pt-sm {
    padding-top: 8px;
}

.pt-xs {
    padding-top: 6px;
}

.py-xs {
    padding-top: 4px;
    padding-bottom: 4px;
}

.py-sm {
    padding-top: 8px;
    padding-bottom: 8px;
}

.py-m {
    padding-top: 12px;
    padding-bottom: 12px;
}

.py-md {
    padding-top: 16px;
    padding-bottom: 16px;
}

.pb-sm {
    padding-bottom: 8px;
}

.pb-xs {
    padding-bottom: 4px;
}

.pb-md {
    padding-bottom: 16px;
}

.py-lg {
    padding-top: 24px;
    padding-bottom: 24px;
}

.py-xl {
    padding-top: 34px;
    padding-bottom: 34px;
}

.px-md {
    padding-right: 16px;
    padding-left: 16px;
}

.px-sm {
    padding-right: 8px;
    padding-left: 8px;
}

.px-xs {
    padding-right: 4px;
    padding-left: 4px;
}

.pa-md {
    padding: 16px;
}

.pb-xl {
    padding-bottom: 32px;
}

.my-sm {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.mr-xl {
    margin-right: 34px;
}

.mt-sm {
    margin-top: 8px !important;
}

.mb-sm {
    margin-bottom: 8px !important;
}

.ml-md {
    margin-left: 16px !important;
}

.mt-md {
    margin-top: 16px !important;
}

.mt-lg {
    margin-top: 24px !important;
}

.mt-xl {
    margin-top: 32px !important;
}

.mb-lg {
    margin-bottom: 24px;
}

.mx-lg {
    margin-left: 24px;
    margin-right: 24px;
}

.mx-md {
    margin-left: 16px;
    margin-right: 16px;
}

.form-label {
    font-size: 15px;
    color: #585858;
    margin-bottom: 8px !important;
}

.form-select,
.form-control {
    padding: 12px;
    background: #FFFFFF !important;
}

.input-group-text {
    box-shadow: 0px 2px 4px 0px #5A5A5A33;
    border-radius: 12px;
}

.text-feild-filled,
.form-select,
.form-control {
    background: #FFFFFF;
    font-size: 12px;
    border: unset;
    color: $charcoal-ligth;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px #5A5A5A33;

    .MuiOutlinedInput-notchedOutline {
        border-width: 0 !important;
    }

    .MuiInputBase-input {
        box-sizing: inherit !important;
        height: 48px;
        font-size: 12px;
    }

    .MuiInputBase-multiline {
        height: unset;
    }
}

.box-shadow {
    box-shadow: 0px 2px 4px 1px #5A5A5A33;
}

.text-justify {
    text-align: justify;
}

.element-center {
    margin: auto;
}

.nav-tabs .nav-link,
.RouterTabs .nav-link {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}

.MuiButton-containedSuccess {
    color: #fff;
}

@media (min-width: 1590px) {
    .MuiContainer-root {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
}

@media (min-width: 1200px) {
    .MuiContainer-root {
        max-width: 1300px !important;
    }
}

@media (max-width: 1023px) {
    .web {
        display: none;
    }

    .mobile-fixed-bottom {
        padding: 14px !important;
        position: fixed;
        bottom: 0;
        display: flex;
        left: 0;
        background: #fff;
        width: 100%;
        min-height: 72px;
        box-shadow: 0px -2px 4px 0px #8E8E8E40;
        align-items: center;
        justify-content: center;

        &.flex {
            flex-direction: column;
        }

        .mobile-full-width {
            width: 100%;

            &:last-child {
                margin-top: 6px;
            }
        }
    }
}

@media (min-width: 1023px) {
    .mobile {
        display: none;
    }
}