.product-catalog-card {
    border-radius: 24px;
    box-shadow: 0px 2px 4px 1px #5A5A5A33;
    border: 1px solid;
    border-image-source: linear-gradient(111.5deg, #F4F5F6 0.11%, rgba(182, 182, 184, 0.37) 78.29%, #F4F5F6 105.13%);
    min-height: 195px;
    background-color: #fff;

    .product-catalog-card-title {
        border-bottom: 1px solid #F8F8F8;
        padding: 14px 10px;
    }

    .product-catalog-card-body {
        padding: 14px 10px;
    }

    .action-area {
        border: 1px solid #F4F5F6;
        box-shadow: 0px 2px 4px 1px #6D6B6B40;
        border-radius: 30px;
        width: 100px;
        padding-top: 3px;
        padding-bottom: 2px;
        display: flex;
        justify-content: space-around;

        svg {
            color: #123DCB;
            font-size: 25px;
            cursor: pointer;

            &.red {
                color: #123DCB;
            }

            &.disable {
                opacity: 0.5;
                cursor: default;
            }
        }
    }
}