.inventory-area .resend-icon {
  display: none;
}

.inventory-area:hover {
  .delivered-icon {
    display: none;
  }

  .resend-icon {
    display: inline-block;
  }
}