@font-face {
    font-family: SFPro-Medium;
    src: url(../font/sf-pro/SFPRODISPLAYMEDIUM.OTF);
}

@font-face {
    font-family: SFPro-Light;
    src: url(../font/sf-pro/SFPRODISPLAYLIGHTITALIC.OTF);
}

@font-face {
    font-family: SFPro-Bold;
    src: url(../font/sf-pro/SFPRODISPLAYBOLD.OTF);
}

@font-face {
    font-family: SFPro-Regular;
    src: url(../font/sf-pro/SFPRODISPLAYREGULAR.OTF);
}

.font-regular {
    font-family: SFPro-Regular;
}

.font-bold {
    font-family: SFPro-Bold;
}

.font-light {
    font-family: SFPro-Light;
}

.font-medium {
    font-family: SFPro-Medium;
}

.font-xl {
    font-size: 32px;
}

.font-lg {
    font-size: 24px;
}

.font-md {
    font-size: 18px;
}

.font-sm {
    font-size: 14px;
}

.font-xl {
    font-size: 32px;
}

.font-main {
    font-size: 16px;
}

.font-xs {
    font-size: 12px;
}

@media (max-width: 1023px) {
    .font-xl {
        font-size: 29px;
    }

    .font-lg {
        font-size: 21px;
    }

    .font-md {
        font-size: 15px;
    }

    .font-sm {
        font-size: 11px;
    }

    .font-main {
        font-size: 13px;
    }

    .font-xs {
        font-size: 9px;
    }
}