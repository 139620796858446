.IdentityLayout {
  .box {
    margin: 100px auto 20px auto;
    width: 50%;
    min-height: 50px;
    background: #f8f9fa;
    border-radius: 10px;
    padding: 30px;
    &.sm{
      width: 25%;
    }
    .center-text,
    h3 {
      text-align: center;
    }
    .icon {
      display: block;
      font-size: 50px;
      color: green;
      margin: 0 auto 30px auto;
    }
    .btn {
      margin-top: 20px;
    }
    .right {
      float: right;
      margin-top: 20px;
    }
    a {
      display: block;
    }
  }

  .footer {
    text-align: center;
    a {
      display: block;
      margin-top: 10px;
      color: rgb(129, 129, 129);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .IdentityLayout .box {
    width: 70%;
    &.sm{
    width: 70%;
    }
  }
}

@media screen and (min-width: 469px) and (max-width: 767px) {
  .IdentityLayout .box {
    width: 80%;
    &.sm{
      width: 80%;
      }
  }
}

@media (max-width: 468px) {
  .IdentityLayout .box {
    width: 90%;
    &.sm{
      width: 90%;
      }
  }
}
