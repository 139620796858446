.category-catalog-item {
    position: relative;
    border-radius: 32px;
    border: 2px solid;
    background-color: #E1E1E1;
    border-image-source: linear-gradient(139.08deg, rgba(191, 214, 187, 0.41) 3.09%, rgba(104, 173, 92, 0.041) 96.91%);
    box-shadow: 0px 2px 4px 1px #5A5A5A33;
    height: 216px;

    .category-image {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 32px;
    }

    .footer {
        position: absolute;
        bottom: 0;
        color: #FFFFFF;
    }

    .border-line {
        height: 1px;
        position: absolute;
        bottom: 32%;
        width: 100%;
        background-image: linear-gradient(90deg, rgba(153, 153, 153, 0) 0%, rgba(255, 255, 255, 0.4) 51.5%, rgba(153, 153, 153, 0) 100%);
    }
}

.express-category-catalog-title {
    font-size: 24px !important; 
}

@media (max-width: 1023px) {
    .category-catalog-item {
        height: 180px;
        font-size: 13px;

        svg {
            width: 22px;
        }
    }

    .express-category-catalog-title {
        font-size: 18px !important;
        color: #444444;
        padding-left: 12.8px;
        padding-right: 12.8px;
    }
}