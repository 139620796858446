.dzu-dropzone {
  overflow: hidden;
  padding: 8px;
  border: 2px dashed #ced4da;
  border-radius: 8px !important;
  min-height: 90px;
  .dzu-inputLabel {
    color: #6c757d;
    font-size: 15px;
    font-family: SFPro-Regular !important;
  }
  .dzu-previewImage {
    max-height: 95px;
    max-width: 140px;
  }
  .dzu-previewContainer {
    padding: 10px 3% !important;
  }
}

.defaultImage-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
