.swiper-background {
  background-color: var(--pale-grey);
  width: 100%;
  height: 240px;
  position: absolute;
  left: 0;
  right: 0;
  &.rtl {
    background-image: linear-gradient(to right, #fff0, #fff);
  }
  &.ltr {
    background-image: linear-gradient(to left, #fff0, #fff);
  }
}
@media (min-width: 280px) and (max-width: 639px) {
  .swiper-background {
    height: 190px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  .swiper-background {
    height: 220px;
  }
}

@media (min-width: 961px) and (max-width: 1100px) {
  .swiper-background {
    height: 210px;
  }
}
