header {
    box-shadow: 0px 2px 6px 1px #76767633 !important;
    background-color: #F6F6F6 !important;
    color: #777777 !important;

    .MuiToolbar-root {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.logo-header {
    padding-left: 24px;
    width: 250px;
}

.header-box {
    padding-right: 16px;
    padding-left: 16px;
}

@media (min-width: 1023px) and (max-width: 1540px) {
    .header-box {
        padding-right: 12px;
        padding-left: 12px;

        svg {
            width: 18px;
        }
    }
    
    .logo-header {
        width: 220px;
        
        svg {
            width: 190px;
        }
    }
}

@media (min-width: 1023px) and (max-width: 1140px) {
    .header-box {
        padding-right: 2px !important;
        padding-left: 2px !important;
        background-color: unset !important;
    }

    .logo-header {
        width: 200px;
        
        svg {
            width: 170px;
        }
    }
}