.ArticleCatalog {
    margin-top: 30px;

    .mobile-category-toggle{
        display: none;
    }

    .article-body{
        display: flex;
        padding-top: 10px;
        border-top: 1px rgb(233, 233, 233) solid;
        
        .categories{
            width: 20%;
        }

        .articles {
            padding: 20px;
            width: 80%;
            display: inline-block;
            margin: 0 5px;
            border-left: 1px solid #f5f5f5;
        }
    }
}

@media (max-width: 960px) {
    .ArticleCatalog {
        .article-body{
            .categories{
                position: fixed;
                display: none;
            }
            
            .articles {
                padding: 20px;
                width: 100%;
                display: block;
                margin: 0 5px;
                border-left: none;
            }
        }

        .mobile-category-toggle{
            display: inline-block;
            padding: 30px 10px 0 10px;
            text-align: justify;
            cursor: pointer;
            color: rgb(60, 60, 60);
            svg {
                font-size: 35px;
            }
        }
    }    
}
