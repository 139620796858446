.card_img {
  img {
    width: 80%;
  }

  @media (min-width: 280px) and (max-width: 468px) {
    img {
      width: 90%;
    }
  }
}
