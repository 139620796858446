.shop-selection {
    width: 200px;
}

@media (min-width: 1140px) and (max-width: 1440px) {
    .shop-selection {
        width: 170px;
    }

    input {
        font-size: 13px !important;
    }
}

@media (min-width: 1023px) and (max-width: 1140px) {
    .shop-selection {
        width: 150px;
    }

    input {
        font-size: 12px !important;
    }
}

@media (max-width: 960px) {
    .shop-selection {
        width: 80%;
    }
}