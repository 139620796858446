* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  padding: 0 !important;
  margin: 0 !important;
  background: #F6F6F6;
  font-family: SFPro-Regular;
}

html,
body {
  height: -o-calc(100vh - 65px);
  /* opera */
  height: -webkit-calc(100vh - 65px);
  /* google, safari */
  height: -moz-calc(100vh - 65px);
  /* firefox */
  background: #F6F6F6;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #e1e3eb;
}

::-webkit-scrollbar-thumb {
  background: #a29eac;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 4px;
  background: #555;
}

.MuiButton-sizeSmall {
  height: 44px;
}