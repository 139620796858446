.FAQ-Slider {
  .swiper {
    width: 100%;
    height: 100vh;
  }
  .swiper-slide {
    object-fit: cover;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
@media (min-width: 280px) and (max-width: 468px) {
  .FAQ-Slider {
    height: 100vh;
    .swiper {
      height: 70vh;
    }
    .swiper-slide {
      text-align: left;
    }
  }
}
