.MenuItem {
  width: 100%;
  padding: 1vh 10px;
  margin-bottom: 1vh;
  cursor: pointer;
  gap: 10px;
  padding: 14px 24px;
  border-bottom: 1px solid #ededed;

  .menu-link-title {
    color: #000;
    padding-left: 12px;
    font-size: 16px;
  }

  &.active {
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(124, 124, 124, 0.25);
    border-radius: 16px;
    border: 1px solid;
    border-image-source: linear-gradient(92.11deg, rgba(179, 179, 179, 0.2) 0.67%, #BCBCBC 99.33%);
    border-radius: 5px;

    .menu-link-title {
      color: #000 !important;
    }

    svg path {
      color: #000;
      fill: #000 !important;
    }
  }

  &.button {
    color: #696969;
  }

  ul li {
    display: block;
    margin-top: 10px;
  }
}

@media (min-width: 1023px) and (max-width: 1540px) {
  .MenuItem {
    font-size: 14px;
  }
}