.ProductCatalog {
    .menu-bar {
        .tab-bar {
            height: 54px;
            width: 100%;
            padding: 8px;
            background-color: #ffffff;
            border-radius: 16px;
            margin-bottom: 3vh;

            .li {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                cursor: pointer;
                color: #606060;
                height: 100%;
                font-size: 13px;

                &.active {
                    color: #ffffff;
                    background-color: #2196F3;
                }
            }
        }

        .mobile-category-toggle {
            display: none;
        }

        .catalog-sort-input {
            display: block;
        }
    }

    .catalog-options {
        text-align: right;
        display: inline-block;
        width: 10%;

        svg {
            font-size: 32px;
            margin-right: 10px;
            color: rgb(145, 145, 145);
            cursor: pointer;
            border-radius: 3px;
            padding: 4px;

            &.active {
                background-color: rgb(223, 223, 223);
            }
        }
    }

    input.catalog-search {
        display: inline-block;
        width: 50%;
        margin: 25px 0;
        background-color: rgb(247, 247, 247);
        border-radius: 25px;
        padding: 10px;
        border: 1px solid #ccc;
        color: rgb(116, 116, 116);

        &:focus {
            outline: none;
        }
    }

    .catalog-body {
        display: flex;

        .categories {
            width: 250px;
        }

        .products {
            padding-top: 32px;
            width: calc(100% - 250px);
        }
    }
}

@media (min-width: 1141px) and (max-width: 1540px) {
    .ProductCatalog {
        .catalog-body {
            .categories {
                width: 220px;
            }

            .products {
                width: calc(100% - 220px);
            }
        }
    }
}

@media (min-width: 1023px) and (max-width: 1140px) {
    .ProductCatalog {
        .catalog-body {
            .categories {
                width: 200px;
            }

            .products {
                width: calc(100% - 200px);
            }
        }
    }
}