#nav-bar-landing {
  &.border-bottom-custom {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  height: 60px;
  background-color: var(--pale-grey) !important;
  position: sticky;
  top: 0;
  z-index: 10;
  .content-end {
    justify-content: end;
  }
  .navbar-brand {
    margin-left: 0px;
  }
  .navbar-toggler {
    border: unset;
    &:active,
    &:hover,
    &:focus {
      border: unset !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

@media (min-width: 280px) and (max-width: 992px) {
  
  .web {
    display: none;
  }
}
