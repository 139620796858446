.card-vertical {
  text-align: center;

  .card_img {
    &img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
