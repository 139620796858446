@media (max-width: 1023px) {
    .alert {

        .alert-body,
        .alert-body span {
            font-size: 12px;
        }
    }

    .MuiStack-root {
        .MuiAlert-icon {
            svg {
                width: 20px;
                height: 20px;
            }
        }

        .MuiAlert-standard {
            a {
                span {
                    font-size: 13px;
                }

                font-size: 13px;
                margin-top: 8px;

                svg {
                    width: 6px;
                    height: 13px;
                }
            }
        }
    }
}

.MuiStack-root {

    .MuiAlert-message {
        width: 100%;
    }

    .MuiAlert-standard {
        border-radius: 12px;
        border: 1.5px solid;
        font-size: 16px;

        &.MuiAlert-standardInfo {

            button,
            a {
                color: #2152F6 !important;
            }

            svg,
            svg path {
                stroke: #2152F6;
            }

            border-color: #2152F6 !important;
            color: #2152F6 !important;
            background: #F6F7FF !important;
        }

        &.MuiAlert-standardWarning {

            button,
            a {
                color: #A58000 !important;
            }

            svg,
            svg path {
                stroke: #A58000;
            }

            border-color: #A58000 !important;
            background: #FFFEED !important;
        }

        &.MuiAlert-standardError {

            button,
            a {
                color: #B90000 !important;
            }

            svg,
            svg path {
                stroke: #B90000;
            }

            border-color: #B90000 !important;
            background: rgb(255, 221, 221) !important;
        }

        &.MuiAlert-standardSuccess {

            button,
            a {
                color: #17AD24 !important;
            }

            svg,
            svg path {
                stroke: #17AD24;
            }

            border-color: #17AD24 !important;
            background: #E3FFDE !important;
        }
    }
}