.FullScreenLoading{
.cover{
    -webkit-pointer-events: none;
    pointer-events: none;
  
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

      background: rgb(121, 31, 162);
      position: fixed;
      z-index: 2000;
      top: 0;
      height: 2px;
}

.image{
    margin: 30vh auto;
    display: block;
  }
}