#search {
    box-shadow: 0px 2px 6px 0px #ACACAC40;
    border-radius: 12px;
    height: 56px;

    ::placeholder {
        color: #A09F9F !important;
    }

    svg {
        color: #A09F9F !important;
        fill: #A09F9F !important;
    }
}