.active-menu-line {
    position: absolute;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background-color: #2196F3;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.MuiListItem-root {
    margin-right: 16px;

    .menu-label {
        font-size: 14px;
    }

    .active {
        color: #2196F3;

        span {
            font-size: 15px;
        }
    }
}

.MuiListItemButton-root {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 ‍ !important;
    padding: 8px 4px !important;
}

@media (min-width: 1023px) and (max-width: 1440px) {
    .MuiListItem-root {
        margin-right: 8px;

        .menu-label {
            font-size: 12px;
        }

        .active {
            color: #2196F3;

            span {
                font-size: 13px;
            }
        }
    }
}