.mobile-categories {
    .category-item {
        height: 44px;
        border-radius: 12px;
        min-width: 100px;
        margin-right: 8px;
        border: 1px solid #E5E5E5;

        &.active {
            box-shadow: 0px 2px 4px 0px #7C7C7C40;
            background: #FFFFFF;
        }

        svg {
            font-size: 20px;
            width: 20px;
            height: 20px;
            margin-right: 4px;
        }
    }

    .horizontal-scrolling {
        height: 50px;
    }

    .horizontal-scrolling::-webkit-scrollbar {
        display: none;
    }
}