.simple-table {
    .MuiTableCell-root {
        font-size: 16px;
        color: #333333;
        border-bottom: 1px solid #E1E5EA !important;
        line-height: 1.68 !important;

    }

    .MuiTableBody-root .MuiTableRow-root {
        &:last-child {
            .MuiTableCell-root {
                border-bottom: unset !important;
            }
        }
    }
}

@media (max-width: 1023px) {

    .grid,
    .simple-table {

        .MuiTableCell-root,
        .MuiTableCell-sizeMedium {
            font-size: 13px !important;
        }
    }
}

.grid,
.simple-table {
    scrollbar-width: thin;

    .MuiTableHead-root {
        .MuiTableCell-root {
            font-family: SFPro-Bold;
        }
    }

    .MuiTableBody-root {
        .MuiTableCell-root {
            font-family: SFPro-Regular;
        }
    }
}

.grid {
    box-shadow: 0px 2px 4px 1px #5A5A5A33;
    background-color: #fff;
    border-radius: 24px;

    .MuiTableHead-root {
        padding: 16px;

        .MuiTableCell-root {
            border-bottom: 1px solid #F1F1F1 !important;
        }
    }

    .MuiTableBody-root {
        padding: 16px;

        .MuiTableCell-root {
            border-bottom: unset;

            &.MuiTableCell-sizeMedium {
                padding: 12px 16px;
                font-size: 14px;
            }
        }
    }

    &.loading {
        table {
            min-height: 40vh;
        }
    }

    .button-disable {
        pointer-events: none;
    }

    .spinner {
        margin: 0 15px;
    }

    .empty-row {
        background-color: #ffffff;

        td {
            text-align: center;
            vertical-align: middle;
            background-color: #ffffff;
        }
    }

    tbody {
        tr.text-danger {
            td {
                color: #db4040;
            }
        }

        tr.text-success {
            td {
                color: green;
            }
        }
    }
}