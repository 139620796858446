@media (max-width: 1023px) {
  .payment {
    .payment-box {
      padding: 22px 16px;
    }
  }
}

.payment {
  .payment-box {
    max-width: 100%;
    width: 830px;
    padding: 24px 20px;
  }

  ul {
    padding: unset;
    margin: unset;
    margin-top: 32px;

    li {
      background: #FAFAFA;
      border: 1px solid #E9E9E9;
      box-shadow: 0px 2px 5px rgba(170, 170, 170, 0.25);
      border-radius: 12px;
      padding: 12px;
      list-style: none;
    }
  }
}