.ql-editor {
  min-height: 120px !important;
}

.quill {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #5A5A5A33;

  .ql-toolbar {
    border: unset !important;
  }

  .ql-container {
    border: unset !important;
  }
}