.disabled {
  pointer-events: none;
}

.wallet-overview {
  padding-right: 16px;
  padding-left: 16px;
}

@media (min-width: 1023px) and (max-width: 1440px) {  
  .amount {
    font-size: 12px;
  }

  .wallet-overview {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 1023px) {
  .wallet-overview {
    padding-left: 4px;
    padding-right: 4px;
  }

  .amount {
    font-size: 12px;
  }
}