.SuspendedMode {
        padding: 20px;
        .head {
        text-align: center;
        svg {
            font-size: 80px;
            color: red;
        }
    }
}
