@media (max-width: 1023px) {
    .identity-layout {
        padding-top: 88px;

        .identity-layout-header {
            position: fixed;
            top: 0;
            height: 62px;
            width: 100%;
            box-shadow: 0px 2px 4px rgba(118, 118, 118, 0.1);
        }
    }

}

@media (min-width: 1023px) {
    .identity-layout {
        min-height: 100vh;
        padding-top: 64px;

        .identity-layout-header {
            display: none;
        }

        .box-area {
            max-width: 400px;
        }
    }
}