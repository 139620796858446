.header {
  .row {
    text-align: justify;
  }
  .sticky-img {
    position: absolute;
    right: 0;
    z-index: -1;
  }
  .image {
    padding-top: 7.2rem;
  }
  .link {
    text-decoration: none;
    color: var(--brownish-red);
    display: contents;
    .circle {
      color: #fff;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--brownish-red);
    }
  }
  .mobile-display {
    display: block;
  }
  @media (min-width: 280px) and (max-width: 991px) {
    .mobile-display {
      display: none;
    }
  }
}
