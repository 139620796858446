:root {
    --size: 0rem;
  }
  .slide-view {
    height: 80vh;
    padding: 0 100px;
    margin: auto;
    animation: show-details 0.5s ease 0.85s;
    -webkit-animation-fill-mode: forwards;
  
    .area-images {
      animation: show-images 0.25s ease 0.625s;
      -webkit-animation-fill-mode: forwards;
      height: 100%;
      left: 0;
      position: relative;
      top: 350px;
  
      img {
        position: absolute;
        bottom: 0;
        opacity: 0;
        animation: rotate-right 0.125s ease 0.75s;
        -webkit-animation-fill-mode: forwards;
        &:nth-child(1n + 5) {
          animation: rotate-left 0.125s ease 0.75s;
          -webkit-animation-fill-mode: forwards;
        }
        &:nth-child(1) {
          z-index: 1;
          right: 0;
        }
        &:nth-child(2) {
          z-index: 2;
          right: var(--size);
        }
        &:nth-child(3) {
          z-index: 3;
          right: calc(var(--size) * 2);
        }
        &:nth-child(4) {
          z-index: 4;
          right: calc(var(--size) * 3);
        }
        &:nth-child(5) {
          animation: init-animation 0.125s ease 0.5s;
          -webkit-animation-fill-mode: forwards;
          margin: 0 auto;
          height: 600px;
          right: 0;
          left: 0;
          z-index: 5;
          bottom: -180px;
          opacity: 1;
        }
        &:nth-child(6) {
          z-index: 4;
          left: calc(var(--size) * 3);
        }
        &:nth-child(7) {
          z-index: 3;
          left: calc(var(--size) * 2);
        }
        &:nth-child(8) {
          z-index: 2;
          left: var(--size);
        }
        &:nth-child(9) {
          z-index: 1;
          left: 0;
        }
      }
    }
  }
  .fade-in {
    animation: fade-in 1s ease-out;
    animation-fill-mode: both;
  }
  
  @keyframes init-animation {
    to {
      height: 356px;
      bottom: 0px;
    }
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes show-images {
    to {
      --size: 6rem;
    }
  }
  
  @keyframes show-details {
    from {
      height: 80vh;
    }
    to {
      height: 330px;
    }
  }
  
  @keyframes rotate-right {
    from {
      transform: rotate(5deg);
      opacity: 0.5;
    }
    to {
      transform: rotate(10deg);
      opacity: 1;
    }
  }
  
  @keyframes rotate-left {
    from {
      transform: rotate(-5deg);
      opacity: 0.5;
    }
    to {
      transform: rotate(-10deg);
      opacity: 1;
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1440px) {
    @keyframes show-images {
      to {
        --size: 6rem;
        width: 100%;
      }
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1400px) {
    .slide-view {
      padding: 0 30px;
    }
  }
  
  @media (max-width: 1200px) {
    @keyframes show-images {
      to {
        --size: 4rem;
        width: 100%;
      }
    }
    .slide-view {
      padding: 0 20px;
    }
  }
  
  @media (max-width: 1023px) {
    @keyframes area-images-animation {
      to {
        top: 150px;
        --size: 1rem;
      }
    }
    .slide-view {
      padding: 0;
      .area-images {
        bottom: 0;
        animation: area-images-animation 0.125s ease 0.5s;
        -webkit-animation-fill-mode: forwards;
        img {
          width: 80px;
          height: 115px;
          &:nth-child(3) {
            z-index: 3;
            right: calc(var(--size) * 2.5);
          }
          &:nth-child(4) {
            z-index: 4;
            right: calc(var(--size) * 4);
          }
          &:nth-child(5) {
            height: 60vh;
            width: 100%;
          }
          &:nth-child(6) {
            z-index: 4;
            left: calc(var(--size) * 4);
          }
          &:nth-child(7) {
            z-index: 3;
            left: calc(var(--size) * 2.5);
          }
        }
      }
    }
    @keyframes init-animation {
      to {
        height: 120px;
        width: 80px;
        bottom: 0px;
      }
    }
    @keyframes show-details {
      from {
        height: 80vh;
      }
      to {
        height: 170px;
      }
    }
  }
  @media (min-width: 354px) and (max-width: 524px) {
    .slide-view {
      max-width: 358px;
    }
    @keyframes area-images-animation {
      to {
        top: 150px;
        --size: 1.5rem;
      }
    }
  }
  @media (min-width: 560px) and (max-width: 991px) {
    .slide-view {
      padding: 0;
      .area-images {
        img {
          width: 150px;
          height: 205px;
        }
      }
    }
    @keyframes init-animation {
      to {
        width: 150px;
        height: 215px;
        bottom: 0px;
      }
    }
    @keyframes show-images {
      to {
        --size: 3.2rem;
      }
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    @keyframes area-images-animation {
      to {
        top: 150px;
        --size: 3rem;
      }
    }
    .slide-view {
      max-width: 700px;
      padding: 0;
      .area-images {
        img {
          width: 160px;
          height: 215px;
          &:nth-child(5) {
            height: 60vh;
            width: 50%;
          }
        }
      }
    }
    @keyframes init-animation {
      to {
        width: 170px;
        height: 230px;
        bottom: 0px;
      }
    }
    @keyframes show-details {
      to {
        height: 100px;
      }
    }
  }
  
  //--------------------------------------------
  
  @media (min-width: 525px) and (max-width: 767px) {
    @keyframes area-images-animation {
      to {
        top: 140px;
        --size: 2rem;
      }
    }
    .slide-view {
      padding: 0;
      .area-images {
        img {
          width: 110px;
          height: 165px;
          &:nth-child(5) {
            height: 60vh;
            width: 65%;
          }
        }
      }
    }
    @keyframes init-animation {
      to {
        width: 110px;
        height: 165px;
        bottom: 0px;
      }
    }
    @keyframes show-details {
      to {
        height: 120px;
      }
    }
  }