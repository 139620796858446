.actions {
  .w-35 {
    width: 35% !important;
  }
  .btn-outline-danger {
    color: var(--brownish-red);
    border-color: var(--brownish-red);
    &:hover {
      --bs-btn-hover-bg: var(--brownish-red);
      --bs-btn-hover-border-color: var(--brownish-red);
      color: #fff
    }
  }
}
@media (min-width: 280px) and (max-width: 468px) {
  .actions {
    .w-35 {
      width: 90% !important;
    }
  }
}

@media (max-width: 768px) {
  .actions {
    gap: 0.5rem;
    display: grid;
    .w-35 {
      width: 70% !important;
    }
    .btn {
      padding: 10px 42px;
      font-size: 1.1rem;
      font-weight: 500;
    }
    .ms-4 {
      margin-left: 0 !important;
    }
  }
}
