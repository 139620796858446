.RouterTabs {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 10px;
    .nav-link {
        display: inline-block;
        color: #6c757de0;
        margin-bottom: -1px;
        background: 0 0;
        border: 1px solid transparent;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        &.active, &:hover{
            color: #495057;
            background-color: #fff;
            border-color: #dee2e6 #dee2e6 #fff;
        }
    }
}
