.article-item {
    width: 100%;
    margin: 20px 0 0 0;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 3px #ccc;
    border-radius: 15px;
    height: 285px;
    overflow: hidden;
    
    .article-title {
      text-align: left;
      font-size: 15px;
      padding: 5px 15px;
      line-height: 25px;
      height: 38px;
      color:#000000;
      font-weight: bold;
    }

    .article-image {
      width: 100%;
      margin: 0 auto;
      display: block;
      min-height: 100px;
      object-fit: fill;
      height: 200px;
      border-radius: 15px 15px 0 0;
      cursor: pointer;
    }

    .article-description {
      padding: 10px 14px;
      font-size: 15px;
    }

    .details-text {
      word-wrap: initial;
    }
  }