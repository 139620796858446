.product-catalog-item {
    position: relative;
    border-radius: 32px;
    border: 2px solid;
    background-color: #E1E1E1;
    border-image-source: linear-gradient(139.08deg, rgba(191, 214, 187, 0.41) 3.09%, rgba(104, 173, 92, 0.041) 96.91%);
    box-shadow: 0px 2px 4px 1px #5A5A5A33;
    height: 216px;

    .product-image {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 32px;
    }

    .footer {
        padding: 16px;
        position: absolute;
        bottom: 0;
        color: #FFFFFF;
    }

    .border-line {
        height: 1px;
        position: absolute;
        bottom: 32%;
        width: 100%;
        background-image: linear-gradient(90deg, rgba(153, 153, 153, 0) 0%, rgba(255, 255, 255, 0.4) 51.5%, rgba(153, 153, 153, 0) 100%);
    }

}

@media (max-width: 1023px) {
    .product-catalog-item {
        font-size: 13px;
        height: 180px;

        .flag {
            width: 16px;
        }

        .footer {
            padding: 12px;
        }

        svg {
            width: 22px;
        }
    }
}