.WalletCharge {
  .StepperContainer-0-2-1 {
    padding: 24px 0 !important;
  }

  .border-line {
    background-color: #EDEDED;
    height: 2px;
    width: 90%;
    margin: auto;
  }

  #RFS-ConnectorContainer {
    left: calc(-50% + 26px);
    right: calc(50% + 26px);

    span {
      border-color: #CACACA66 !important;
      border-width: 2px !important;
    }
  }

  #RFS-LabelContainer {
    span {
      font-weight: unset !important;
      font-family: SFPro-Bold !important;
    }
  }

  .deposit-box {
    max-width: 100%;
    width: 830px;
  }


  .stepper-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 34px 40px;

    .body-title {
      font-size: 18px;
    }

    .amount-size {
      font-size: 18px;
    }

    .body-sub-title {
      color: #6f6f6f;
    }

    .body-footer {
      display: flex;
      justify-content: flex-end;
    }
  }

  ul {
    margin: 50px 0;
    padding: unset;

    li {
      background: #FAFAFA;
      border: 1px solid #E9E9E9;
      box-shadow: 0px 2px 5px rgba(170, 170, 170, 0.25);
      border-radius: 12px;
      padding: 12px;
      list-style: none;
    }
  }
}

@media (max-width: 768px) {
  .WalletCharge {
    .stepper-body {
      width: 100%;
      margin: 0 auto;
    }

    ul {
      padding: unset;

      li {
        width: 100%;
        margin: unset !important;
      }
    }
  }
}