.article-details-container {
    width: 100%;

    .article-details-body {
        width: 60%;
        height: auto;
        flex-direction: column;
    
        img {
            max-width: 100%;
        }

        .main-img {
            border-radius: 8px;
            margin-top: 12px;
            margin-bottom: 32px;
        }

        .details {
            margin-top: 24px;
        }
    }
}

@media (max-width: 960px) {
    .article-details-body {
        width: 100% !important;
    }
}