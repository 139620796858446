#landing {
  .landing-footer {
  background-color: #f8f9fa;
    padding: 2rem 0;
    bottom: 0px;
    width: 100%;
    .content-end {
      padding-top: 2.5rem;
    }
    @media (min-width: 280px) and (max-width: 468px) {
      .content-end {
        padding-top: 0;
      }
    }
  }
}
