.Cart {
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 3px #ccc;
    border-radius: 10px;

    .cart-submit {
        width: 100%;
        padding: 10px 0;
        text-align: center;
        background-color: rgb(154, 184, 167);
        border-radius: 0 0 10px 10px;
        color: rgb(2, 63, 29);
        cursor: pointer;

        &:hover {
            background-color: rgb(125, 172, 145);
        }
    }

    h6 {
        padding: 10px;

        .delete {
            float: right;
            color: red;
            cursor: pointer;
        }
    }

    &.empty {
        background-color: unset;
        box-shadow: none;
        text-align: center;
        padding-top: 50px;
    }

    p {
        color: rgb(166, 170, 173);

        .icon {
            display: block;
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
}