.catalog-search {
  width: 100%;
  display: block;
  background-color: rgb(247, 247, 247);
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  color: rgb(116, 116, 116);
  &:focus {
    outline: none;
  }
}
