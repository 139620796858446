.website {
  background-color: #fff;
  height: 100%;
  width: 100%;
}
#landing {
  .text-greyish-brown {
    color: var(--greyish-brown);
  }
  .warm-grey-text {
    color: #999999;
  }
  .white-80-text {
    color: rgba(237, 237, 237, 0.8);
  }
  .fw-medium {
    font-weight: 600;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .font-bold {
    font-family: Roboto-Bold;
  }
  .font-medium {
    font-family: Roboto-Medium;
  }
  .text-black {
    color: var(--black-two);
  }
  .text-grey {
    color: var(--warm-grey);
  }
  .text-brownish-red {
    color: var(--brownish-red);
  }
  .line-normal {
    line-height: 2rem;
  }
  .mt-lg {
    padding-top: 7.2rem;
  }
  .pt-md {
    padding-top: 3rem;
  }
  .pt-7 {
    padding-top: 6rem;
  }
  .m-lg {
    margin-top: 8rem;
  }
  .p-large {
    padding: 5rem 4rem;
  }
  .plr-large {
    padding: 0 4rem;
  }
  .pa-md {
    padding: 3rem;
  }
  .justify-items-center {
    justify-items: center;
  }
  a {
    text-decoration: none !important;
  }
  .fs-md {
    font-size: 1.5rem;
  }
  .fs-sm {
    font-size: 1.15rem;
  }
  .fs-7 {
    font-size: 0.9rem;
  }
  .flex-1 {
    flex: 1;
  }
  .btn-brownish-red {
    &.btn {
      border-radius: 10px;
      padding: 12px 42px;
      font-size: 1.1rem;
      font-weight: 500;
      --bs-btn-border-color: var(--brownish-red) !important;
      &.btn-danger {
        border-color: var(--brownish-red);
        background-color: var(--brownish-red);
      }
      &:hover {
        background-color: var(--brownish-red);
        --bs-btn-border-color: var(--brownish-red) !important;
      }
    }
  }
  .d-grid {
    display: grid;
    &.action {
      justify-items: stretch;
      justify-content: center;
      align-items: center;
    }
  }
  .mobile {
    display: none;
  }
}
:root {
  --greyish-brown: #404040;
  --black-two: #262626;
  --pale-grey: #f6f7f9;
  --warm-grey: #828282;
  --brownish-red: #992020;
}
@media (min-width: 280px) and (max-width: 468px) {
  #landing {
    .mt-lg {
      padding-top: 2rem;
    }
    .pt-md {
      padding-top: 0.2rem;
    }
    .fs-md {
      font-size: 1rem;
    }
    .fs-sm {
      font-size: 0.9rem;
    }
    .fs-7 {
      font-size: 0.84rem;
    }
    .p-large {
      padding: 2rem 0.5rem;
    }
    .plr-large {
      padding: 0 0.5rem;
    }
    .pa-md {
      padding: 0.5rem;
    }
    .pt-7 {
      padding-top: 1rem;
    }
    .m-lg {
      margin-top: 4rem;
    }
    .btn-brownish-red {
      &.btn {
        padding: 10px 42px;
        width: 100%;
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
@media (min-width: 469px) and (max-width: 991px) {
  #landing {
    direction: ltr !important;
    .pa-md {
      padding: 3rem 0;
    }
    .btn-brownish-red {
      &.btn {
        font-size: 1rem;
        margin-bottom: 2rem;
      }
    }
  }
}
@media (max-width: 1024px) {
  #landing {
    .mobile {
      display: block;
    }
    .web {
      display: none;
    }
  }
}
