.Alerts {
  .border-bottom {
    border-bottom: 1px solid #cccccc;
  }

  .menu-dropdown-header {
    border-bottom: 1px solid #cccccc;
    padding: 5px 10px;
    margin-bottom: 0;
  }

  .menu-dropdown {
    position: absolute;
    right: 0;
    top: 60px;
    min-width: 360px;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%);
    transition: visibility 0s linear 120ms, opacity 120ms ease;
    border-radius: 12px;
    border: unset;

    .notifications-area {
      &.scroll {
        max-height: 400px;
        overflow-y: scroll;
      }

      .seen {
        opacity: 0.7;
        background: #f8f9fa;
      }
    }
  }

  .alert-toggle {
    position: relative;
    cursor: pointer;
  }

  .red-number {
    background-color: red;
    color: white;
    font-size: 11px;
    padding: 1px 5px;
    border-radius: 30px;
    position: absolute;
    left: 0;
    bottom: 16px;
  }

  .footer-actions {
    display: block;

    .dropdown-item.active,
    .dropdown-item:active {
      text-decoration: none;
      background-color: #fff;
    }

    a {
      color: #9ea4ad;
      text-align: center;
      display: block;
      border-bottom: 1px solid #ccc;
      padding: 5px 0;
      cursor: pointer;

      &:hover {
        color: #4572c7;
      }

      &:last-child {
        border: 0;
      }
    }
  }
}