@media (min-width: 280px) and (max-width: 991px) {
  .credit-card {
    .action {
      padding-top: 1.2rem;
      .btn {
        margin-top: 20px;
      }
    }
  }
}
