.counter-area {
    border: 1px solid #F4F5F6;
    box-shadow: 0px 2px 4px 1px #6D6B6B40;
    border-radius: 30px;
    width: 100px;
    padding-top: 3px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    svg {
        width: 22px;
        height: 21px;
        color: #123DCB;
        font-size: 25px;
        cursor: pointer;

        &.red {
            color: #123DCB;
        }

        &.disable {
            opacity: 0.5;
            cursor: default;
        }
    }
}