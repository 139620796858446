.suggestion-order{
 
    table {
        width: 100%;
        .btn-sm{
        font-size: 15;
        padding-left: 15px;
        padding-right: 15px;
        }
tbody{

 
    tr:nth-child(even){
            background-color: rgb(248, 248, 248);
    }
    tr{
        &:hover{
            background-color: rgb(230, 229, 229);
        }
    }
}

        
    }
    
}