.ticket-view {
  .systemic-text {
    color: #7a7a7a;
  }

  .attachment-style {
    width: 180px;
    height: 120px;
    cursor: zoom-in;
    object-fit: contain;
    border-radius: 4px;
  }

  .zoom-img {
    display: flex;
    flex-wrap: wrap;

    input[type=checkbox] {
      display: none;
    }

    input[type=checkbox]:checked~label>div {
      width: 100%;
      height: 100%;
      cursor: zoom-out;
      background-color: rgb(0 0 0 / 42%);
      position: fixed;
      z-index: 100;
      display: flex;
      left: 0;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
    }
    input[type=checkbox]:checked~label>div>img {
      width: unset;
      height: unset;
      max-height: 90%;
      max-width: 90%;
      cursor: zoom-out;
    }
  }
}