.grid {
  .page-options {
    display: flex;

    .pagination {
      width: 90%;
    }

    .page-size {
      width: 10%;
    }
  }
}