.bottom-navigation {
    position: fixed;
    bottom: 0;
    top: auto;
    z-index: 10;
    width: 100%;
    border-radius: 30px 30px 0 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 110px 0px #0000001A;

    &::before {
        content: "";
        position: absolute;
        top: -60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 89%;
        background: #FFFFFF;
        height: 100px;
        border-radius: 100px 100px 0 0;
        clip-path: ellipse(50% 20% at 50% 99%);
    }

    .search-style {
        background: linear-gradient(188.55deg, #B8E0FF -26.37%, #59ABEC 31.55%, #0C65AB 100%);
        position: absolute;
        left: 0;
        top: -27px;
        width: 58px;
        height: 58px;
        display: flex;
        right: 0;
        margin: auto;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
    }
}