.CapitalsOverview {
    tr {
        border-bottom: rgb(189, 189, 189);
    }

    .icon {
        padding: 22px 0;
    }

    .mini-icon svg {
        font-size: 18px;
    }

    svg {
        margin-right: 10px;
        font-size: 30px;
        cursor: pointer;
    }

    position: relative;

    .profile-dropdown {
        h5 {
            color: rgb(93, 120, 93);
            padding: 0 10px;
        }

        position: absolute;
        padding: 10px 0;
        left: 0;
        top: 70px;
        min-width: 900px;
        z-index: 1000;
        background-color: #ffffff;
        box-shadow: 0 24px 54px rgb(0 0 0 / 15%),
        0 4.5px 13.5px rgb(0 0 0 / 8%);
        transition: visibility 0s linear 120ms,
        opacity 120ms ease;
        border-radius: 5px;
        border: unset;

        .main-info {
            text-align: left;
            width: 70%;
            float: left;
            padding: 10px 20px;

            span {
                display: block;
            }
        }

        .footer-actions {
            display: block;

            .dropdown-item.active,
            .dropdown-item:active {
                text-decoration: none;
                background-color: #fff;
            }

            a {
                color: #9ea4ad;
                text-align: center;
                display: block;
                border-bottom: 1px solid #ccc;
                padding: 5px 0;
                cursor: pointer;

                &:hover {
                    color: #4572c7;
                }

                &:last-child {
                    border: 0;
                }
            }
        }
    }
}