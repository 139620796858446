.banners {
    // padding: 0 8px;
    
    .col {
        padding: 0 5px;
    }

    img {
        border-radius: 5px;
        margin: 0;
        display: block;
        width: 100%;
        height: 100%;
    }

    .banner {
        width: 100%;
        height: 100%;
    }

    .main-banner {
        padding-right: 32px;
        padding-top: 26px;
    }
}