.admin-navmenu {
  .MuiListItem-root {
    margin-right: 16px;

    .menu-label {
      font-size: 14px;
    }

    .active {
      color: #2196F3;

      span {
        font-size: 15px;
      }
    }

    .MuiCollapse-root {
      position: absolute;
      top: 50px;
      background: white;
      min-width: 130px;
      border-radius: 12px;
      padding: 0 12px;
      z-index: 2;
    }
  }

  .MuiListItemButton-root {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 ‍ !important;
    padding: 8px 4px !important;
  }

  @media (min-width: 1023px) and (max-width: 1440px) {
    .MuiListItem-root {
      margin-right: 8px;

      .menu-label {
        font-size: 12px;
      }

      .active {
        color: #2196F3;

        span {
          font-size: 13px;
        }
      }
    }
  }
}