.lErww>.wrapper {
    z-index: 1;
}


.shop-select-box {
    &.form-select {
        border-radius: 12px;
        border: 0px solid;
        color: "#333333";
        height: 48px;
        font-size: 14px;
    }

}
select:focus {
    border-radius: 12px;
    border: 0px solid;
    color: "#333333";
    font-size: 14px;
}