#landing {
  .nav-link {
    font-size: 1.2rem !important;
    margin: 0.6rem;
    a {
      color: var(--black-two);
      text-decoration: none;
    }
  }

  
.login-btn{
  background: #992020;
  border-radius: 5px;
  a{
    color: white !important;
  }
}
  @media (min-width: 280px) and (max-width: 468px) {
    .ms-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .nav-link {
      font-size: 1rem !important;
      padding-right: unset !important;
      padding-left: unset !important;
    }

    
.login-btn{
  background: unset;
}
  }
}

