.text-success {
    color: rgb(248, 10, 10);
}

.text-danger {
    color: rgb(47, 127, 81);
}

@media (min-width: 576px) {
    .two-factor, .verify-code, .recovery-code {
        .modal-dialog {
            max-width: 500px
        }
    }
}