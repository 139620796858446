  .CategorySidebar {
    box-sizing: border-box;
    margin: 0 10px;
    border-radius: 0 0 0 5px;
    padding: 32px 4% 5vh 4%;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: #afb7c0 rgb(219, 224, 226);

    &.fix-margin {
      padding: 0;
      margin: 0;
    }

    .fixed-button {
      color: #696969;
      padding: 5px 9px;
      width: 94%;
      display: block;
      border-bottom: 1px solid #e7e7e7;

      svg {
        vertical-align: text-top;
        margin-right: 5px;
      }

      &:hover {
        color: #000000;
      }
    }
  }

  @media (max-width: 320px) {}